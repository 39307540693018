import React from 'react';
import UXDesign from '../../../image/UX-Design.png';
import UIDesign from '../../../image/UI-Design.png';
import Branding from '../../../image/Branding.png';
import { Carousel } from 'react-bootstrap';


const services = [
    {
        img: UXDesign,
        title: 'UX Design',
        description: 'I start every design project off by creating flow charts, wireframes and prototypes. This helps create a foundation that comes together throughout the design process to help make sure that you have a clear perspective on where the product is going with the end-product in mind.'
    },
    {
        img: UIDesign,
        title: 'UI Design',
        description: `When designing your app's UI, it is important to visualize the interface. A strong visual identity can be a great way to help users understand how to interact with the interface by defining how it looks and how it reacts. This includes layouts, animations, and interactions between UI states.`
    },
    {
        img: Branding,
        title: 'Branding',
        description: `A brand is a key element to a successful business. It's the face of your business and is what customers will remember. Creating a strong visual identity for your brand can help customers recognize you. I'll create an identity that will keep customers returning.`
    }
]

const Services = () => {
    const designHeaderStyle = { margin: '20px 0', fontWeight: 500, fontSize: 32 };
    const designParagraphStyle = { color: 'rgba(9, 33, 45, 0.69)', fontSize: 18, lineHeight: '177%', fontWeight: 400 };
    return (
        <div className='container pt-5 pb-5'>
            <h2 className='lg-50-md-30' style={{ fontWeight: 600, marginBottom: 70 }} data-aos="fade-down" data-aos-duration="3000">Design services I provide</h2>
            <div id="services-lg-div" className="row">
                {
                    services.map((service, index) => <div
                        key={index}
                        className="service-div col-lg-4 col-6 p-4">
                        <img data-aos="fade-up" data-aos-duration="1000" style={{ width: 92 }} src={service?.img} alt="" />
                        <h4 data-aos="fade-up" data-aos-duration="2000" style={designHeaderStyle}>{service?.title}</h4>
                        <p data-aos="fade-up" data-aos-duration="3000" style={designParagraphStyle}>{service.description}</p>
                    </div>)
                }
            </div>
            <div id="services-sm-div">
                <Carousel variant="dark">
                    {
                        services.map((service, index) => <Carousel.Item
                            key={index}
                            className="service-div text-center p-4">
                            <img data-aos="fade-up" data-aos-duration="1000" style={{ width: 92 }} src={service?.img} alt="" />
                            <h4 data-aos="fade-up" data-aos-duration="2000" style={designHeaderStyle}>{service?.title}</h4>
                            <p data-aos="fade-up" data-aos-duration="3000" className="p-4" style={designParagraphStyle}>{service.description}</p>
                        </Carousel.Item>)
                    }
                </Carousel>
            </div>
        </div>
    );
};

export default Services;