import React from 'react';
import LetsMake from '../../share/LetsMake/LetsMake';
import aboutImg from '../../../image/about.png';

const About = () => {
    return (
        <div>
            <div className='container'>
                <div className="row flex-md-row-reverse align-items-center">
                    <div className="col-md-6 p-5">
                        <img data-aos="fade-left" data-aos-duration="3000" className='w-100' src={aboutImg} alt="" />
                        <div className='row border-top border-bottom border-1 border-secondary mt-4'>
                            <div data-aos="fade-up" data-aos-duration="2000" className="col-6">
                                <h2 className="lg-50-md-37" style={{ color: '#f9572e', fontWeight: 500, margin: '15px 0' }}>6+</h2>
                                <p className="lg-22-md-16" style={{ color: 'rgba(9, 33, 45, 0.8)' }}>Years of Experience</p>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="3000" className="col-6">
                                <h2 className="lg-50-md-37" style={{ color: '#f9572e', fontWeight: 500, margin: '15px 0' }}>300+</h2>
                                <p className="lg-22-md-16" style={{ color: 'rgba(9, 33, 45, 0.8)' }}>Happy Clients</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h1 data-aos="fade-up" data-aos-duration="1000" className="lg-72-md-33" style={{ fontWeight: 600, lineHeight: '75%', margin: '20px 0 60px 0' }}>Hello,</h1>
                        <p data-aos="fade-up" data-aos-duration="2000" className='paragraphStyle'> I am Axay Devikar and I believe that anyone with an idea should have the power to bring it to reality. My goal is to use my passion and expertise in design to help people bring the best version of their idea into the world.</p>
                        <p data-aos="fade-up" data-aos-duration="2500" className='paragraphStyle'>I am a UX/UI designer who loves working on products from the ground up. Currently, most of my projects are done in Figma where I do everything from high-level wire framing to pixel-perfect design. With experience in programs such as Adobe Illustrator and Sketch, I really enjoy taking it to the next level and learning how to produce design assets myself instead of outsourcing it or purchasing it from a stock agency; including vector illustrations, UI actions and simple logo designs.</p>
                        <p data-aos="fade-up" data-aos-duration="3000" className='paragraphStyle'>I work with all clients from entrepreneur to small startups to help create or refresh their application. I use my creative skills to improve the UI Design and bring life to your older websites and apps. If you need help making your awesome idea even more awesome then let's talk!</p>
                    </div>
                </div>
            </div>
            <LetsMake />
        </div>
    );
};

export default About;