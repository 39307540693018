import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal';

const ContactForm = () => {
    const [modalShow, setModalShow] = useState(false);
    const [button, setButton] = useState(true);
    const [emailSendData, setEmailSendData] = useState(true);

    const [currency, setCurrency] = useState('USD');
    const [webDesign, setWebDesign] = useState(false);
    const [mobileApp, setMobileApp] = useState(false);
    const [saasDesign, setSaasDesign] = useState(false);
    const [fiverr, setFiverr] = useState(false);
    const [google, setGoogle] = useState(false);
    const [fbInsta, setFbInsta] = useState(false);
    const [recommendation, setRecommendation] = useState(false);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data => {
        const service = `${webDesign ? 'Web UI/UX Design, ' : ''}${mobileApp ? 'Mobile App UI/UX Design, ' : ''}${saasDesign ? 'Dashboard Saas Design' : ''}`;
        const findMe = `${fiverr ? 'Fiverr, ' : ''}${google ? 'Google, ' : ''}${fbInsta ? 'Facebook/instagram, ' : ''}${recommendation ? 'Recommendation' : ''}`

        if (!webDesign && !mobileApp && !saasDesign) {
            alert('You does not Select What do you need help with? Please Select anyone!!')
        } else {
            setButton(false);
            const getData = {
                name: data.name,
                company: data.company,
                service,
                based: data.based,
                aboutProject: data.aboutProject,
                budget: data.budget + currency,
                email: data.email,
                findMe
            }
            fetch('https://sleepy-cove-07493.herokuapp.com/', {
                method: 'POST',
                body: JSON.stringify(getData),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
                .then(res => res.json())
                .then(data => {
                    setModalShow(true);
                    setEmailSendData(data);
                    setButton(true);
                    reset();
                });
        }

    };

    const onClickCurrency = () => {
        document.getElementById('currency-accordion-header').childNodes[0].click()
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor="name" className='mb-2'>Name</label>
                <input style={{ height: '60px' }} {...register("name", { required: true })} className="form-control" placeholder="Name" />
                {errors.name && <p style={{ color: '#f9572e' }}>Name field is required</p>}


                <label htmlFor="company" className='mt-4 mb-2'>Company</label>
                <input style={{ height: '60px' }} {...register("company")} className="form-control" placeholder="Company" />


                <Accordion>
                    <label htmlFor="company" className='mt-4 mb-2'>What do you need help with?</label>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header style={{ height: '60px' }} >
                            <span className='text-secondary'>Select From Dropdown</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <input onChange={() => setWebDesign(!webDesign)} type="checkbox" /> <label htmlFor="" className={webDesign ? 'selected-option' : 'none-selected-option'}>Web UI/UX Design</label>
                            </div>
                            <div>
                                <input onChange={() => setMobileApp(!mobileApp)} type="checkbox" /> <label htmlFor="" className={mobileApp ? 'selected-option' : 'none-selected-option'}>Mobile App UI/UX Design</label>
                            </div>
                            <div>
                                <input onChange={() => setSaasDesign(!saasDesign)} type="checkbox" /> <label htmlFor="" className={saasDesign ? 'selected-option' : 'none-selected-option'}>Dashboard Saas Design</label>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>


                <label htmlFor="based" className='mt-4 mb-2'>Where are you based</label>
                <input style={{ height: '60px' }} {...register("based")} className="form-control" placeholder="Where are you based" />


                <label htmlFor="aboutProject" className='mt-4 mb-2'>A few words about the project</label>
                <textarea style={{ height: 126 }} {...register("aboutProject")} className="form-control" placeholder="A few words about the project" />


                <label htmlFor="budget" className='mt-4 mb-2'>Budget</label>
                <div className="d-flex">
                    <Accordion>
                        <Accordion.Item  eventKey="0" style={{ width: 100 }}>
                            <Accordion.Header id="currency-accordion-header" style={{ height: '58px' }} >
                                <span className='text-secondary'>{currency}</span>
                            </Accordion.Header>
                            <Accordion.Body onClick={() => onClickCurrency()} id="currency-accordion-body" style={{ padding: 0, textAlign: 'center' }}>
                                <p className={`${currency === "USD" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("USD")}>USD</p>
                                <p className={`${currency === "NRI" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("NRI")}>NRI</p>
                                <p className={`${currency === "AUD" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("AUD")}>AUD</p>
                                <p className={`${currency === "CAD" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("CAD")}>CAD</p>
                                <p className={`${currency === "CZK" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("CZK")}>CZK</p>
                                <p className={`${currency === "DKK" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("DKK")}>DKK</p>
                                <p className={`${currency === "EUR" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("EUR")}>EUR</p>
                                <p className={`${currency === "GBP" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("GBP")}>GBP</p>
                                <p className={`${currency === "HUF" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("HUF")}>HUF</p>
                                <p className={`${currency === "ILS" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("ILS")}>ILS</p>
                                <p className={`${currency === "JPY" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("JPY")}>JPY</p>
                                <p className={`${currency === "MXN" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("MXN")}>MXN</p>
                                <p className={`${currency === "NOK" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("NOK")}>NOK</p>
                                <p className={`${currency === "NZD" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("NZD")}>NZD</p>
                                <p className={`${currency === "PHP" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("PHP")}>PHP</p>
                                <p className={`${currency === "PLN" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("PLN")}>PLN</p>
                                <p className={`${currency === "RUB" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("RUB")}>RUB</p>
                                <p className={`${currency === "SEK" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("SEK")}>SEK</p>
                                <p className={`${currency === "SGD" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("SGD")}>SGD</p>
                                <p className={`${currency === "THB" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("THB")}>THB</p>
                                <p className={`${currency === "TWD" ? "select-currency" : ""} currency-option`} onClick={() => setCurrency("TWD")}>TWD</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <input type="number" style={{ height: '60px' }} {...register("budget")} className="form-control" placeholder="Budget" />
                </div>


                <label htmlFor="email" className='mt-4 mb-2'>Email</label>
                <input type="email" style={{ height: '60px' }} {...register("email", { required: true })} className="form-control" placeholder="Email" />
                {errors.email && <p style={{ color: '#f9572e' }}>Email field is required</p>}


                <Accordion>
                    <label htmlFor="company" className='mt-4 mb-2'>How did you hear about me?</label>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header style={{ height: '60px' }} >
                            <span className='text-secondary'>Select From Dropdown</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <input onChange={() => setFiverr(!fiverr)} type="checkbox" /> <label htmlFor="" className={fiverr ? 'selected-option' : 'none-selected-option'}>Fiverr</label>
                            </div>
                            <div>
                                <input onChange={() => setGoogle(!google)} type="checkbox" /> <label htmlFor="" className={google ? 'selected-option' : 'none-selected-option'}>Google</label>
                            </div>
                            <div>
                                <input onChange={() => setFbInsta(!fbInsta)} type="checkbox" /> <label htmlFor="" className={fbInsta ? 'selected-option' : 'none-selected-option'}>Facebook/instagram</label>
                            </div>
                            <div>
                                <input onChange={() => setRecommendation(!recommendation)} type="checkbox" /> <label htmlFor="" className={recommendation ? 'selected-option' : 'none-selected-option'}>Recommendation</label>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                {
                    emailSendData
                        ?
                        <MyVerticallyCenteredModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                        :
                        <p>Sorry Email Sending Failed!!!</p>
                }
                {/* <button onClick={() => setModalShow(true)}>test</button> */}
                <br />
                <div className='text-center'>
                    {
                        button ?
                            <button type="submit" className='button-style rounded-pill' >Submit <FontAwesomeIcon icon={faArrowRightLong} /> </button>
                            :
                            <button style={{ background: 'gray' }} className='button-style rounded-pill'>Submit <FontAwesomeIcon icon={faArrowRightLong} disabled /> </button>
                    }
                </div>
            </form>
        </div>
    );
};

export default ContactForm;