import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logoImg from '../../../image/Logo.png';
import facebookImg from '../../../image/Facebook.png';
import dribbleImg from '../../../image/Dribbble.png';
import instagramImg from '../../../image/Instagram.png';
import behanceImg from '../../../image/Behance.png';
import twitterImg from '../../../image/Twitter.png';


const Footer = () => {
    const location = useLocation();
    const path = location.pathname;
    return (
        <div >
            <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.11)' }} className="mt-5 mb-5"> 
                <div className='container'>
                    <div className="row align-items-center">
                        <div className="col-lg-7 pb-2">
                            <div className="row">
                                <Link to="/" className="col-lg-2 text-center">
                                    <img src={logoImg} alt="" />
                                </Link>
                                <div className="col-lg-10 text-lg-end text-center">
                                    <div className='d-flex flex-wrap align-items-center justify-content-center'>
                                        <Link to="/home" className={`nav-link-btn lg-to-md-14 ${path === '/' ? "active-nav-link-btn" : ""} ${path === '/home' ? "active-nav-link-btn" : ""}`}>Home</Link>
                                        <Link to="/portfolio" className={`nav-link-btn lg-to-md-14 ${path === '/portfolio' ? "active-nav-link-btn" : ""}`}>Portfolio</Link>
                                        <Link to="/about" className={`nav-link-btn lg-to-md-14 ${path === '/about' ? "active-nav-link-btn" : ""}`}>About Me</Link>
                                        <Link to="/testimonial" className={`nav-link-btn ${path === '/testimonial' ? "active-nav-link-btn" : ""}`}>Testimonial</Link>
                                        {/* <Link to="/blogs" className={`nav-link-btn ${path === '/blogs' ? "active-nav-link-btn" : ""}`}>Blogs</Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 text-lg-end text-center pb-2">
                            <a href="https://twitter.com/axaydevikar" target="_blank" rel="noopener noreferrer">
                                <img style={{ wight: 37, padding: '0 10px' }} src={twitterImg} alt="" />
                            </a>
                            <a href="https://www.facebook.com/axay.devikar.5/" target="_blank" rel="noopener noreferrer">
                                <img style={{ wight: 37, padding: '0 10px' }} src={facebookImg} alt="" />
                            </a>
                            <a href="https://www.instagram.com/axaydevikar/" target="_blank" rel="noopener noreferrer">
                                <img style={{ wight: 37, padding: '0 10px' }} src={instagramImg} alt="" />
                            </a>
                            <a href="https://www.behance.net/axaydevikar0" target="_blank" rel="noopener noreferrer">
                                <img style={{ wight: 37, padding: '0 10px' }} src={behanceImg} alt="" />
                            </a>
                            <a href="https://dribbble.com/AxayDevikar" target="_blank" rel="noopener noreferrer">
                                <img style={{ wight: 37, padding: '0 10px' }} src={dribbleImg} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <footer >
                <p className='text-center mt-4 mb-4'><small>
                    © 2022 <span style={{ color: '#f9562e', textDecoration: 'underline' }}>axaydevikar</span>. All Rights Reserved
                </small></p>
            </footer>
        </div>
    );
};

export default Footer;