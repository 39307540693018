import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
    body{
        background: white;
        color: #09212D;
        font-family: 'Poppins', sans-serif;;
    }
    .paragraphStyle{
        text-align: justify;
        font-size: 20px;
        line-height: 177%;
        font-weight: 400;
        color: rgba(9, 33, 45, 0.69);
        margin-bottom: 45px
    }
    
    .nav-link-btn{
        padding: 3px;
        margin: 18px;
        text-decoration: none;
        font-weight: 700;
        color: rgba(13, 47, 63, 0.69);
        transition: 1s;
    }
    .nav-link-btn:hover, .active-nav-link-btn{
        color: #F9572E;
        border-bottom: 3px solid;
    }
    .button-style{
        height: 60.26px;
        background: #F9572E;
        font-weight: 500;
        padding: 10px 30px;
        color: white;
        border: none;
        box-shadow: 0px 14px 26px rgba(249, 87, 46, 0.5);;
    }
    .button-white{
        height: 65.63px;
        background: white;
        font-weight: 600;
        padding: 10px 10px 10px 20px;
        color: black;
        border: none;
    }
    .select-currency{
        color: #F9572E;
    }
    .currency-option{
        padding: 5px 0;
        cursor: pointer;
    }
    .currency-option:hover{
        background: #f9572e;
        color: white;

    }
    
    .selected-option{
        color: #F9572E;
    }
    .none-selected-option{
        color: black;
    }
    #services-sm-div{
        display: none;
    }
    .lg-70-md-43{
        font-size: 70px;
        font-weight: 600;
    }
    .lg-72-md-33{
        font-size: 72px;
    }
    .lg-60-md-30{
        font-size: 60px;
        line-height: 90px;
    }
    .lg-54-md-33{
        font-size: 54px;
        font-weight: 300;
    }
    .lg-50-md-37{
        font-size: 50px;
    }
    .lg-50-md-30{
        font-size: 50px;
        font-weight: 400;
    }
    .lg-37-md-24{
        font-size: 37px;
    }
    .lg-24-md-16{
        font-size: 24px;
    }
    .lg-22-md-16{
        font-size: 22px;
    }
    .lg-22-md-14{
        font-size: 22px;
    }
    .nav-header-button{
        margin-left: 100px;
    }
    
    @media only screen and (max-width: 900px) {
        #services-lg-div {
          display: none;
        }
        #services-sm-div{
            display: block;
        }
        .nav-link-btn{
            font-size: 14px;
            margin: 28px 8px 8px 0;
        }
        .lg-70-md-43{
            font-size: 43px;
        }
        .lg-50-md-37{
            font-size: 37px;
        }
        .lg-54-md-33, .lg-72-md-33{
            font-size: 33px;
        }
        .lg-50-md-30, .lg-60-md-30{
            font-size: 30px;
            line-height: 40px;
        }
        .lg-37-md-24{
            font-size: 24px;
        }
        .lg-24-md-16, .lg-22-md-16{
            font-size: 16px;
        }
        .paragraphStyle, .lg-22-md-14, .lg-to-md-14{
            font-size: 14px;
        }
    }
`