import React from 'react';
import ContactForm from '../ContactForm/ContactForm';
import quotesImg from '../../../image/quotes_3.png';

const Contact = () => {
    return (
        <div className='container'>
            <div className="row mt-5 mb-5">
                <div className="col-md-6">
                    <h1 className="lg-70-md-33 fw-bolder" data-aos="fade-up" data-aos-duration="1500" >Say<img style={{ height: 55 }} src={quotesImg} alt="" />Hi!</h1>
                    <p className="lg-24-md-16" data-aos="fade-up" data-aos-duration="3000">I'd love to chat about your new web project. Please send me as much information as possible so we can meet up and get the most out of our first meeting.</p>
                </div>
                <div className="col-md-6">
                    <ContactForm />
                </div>
            </div>
        </div>
    );
};

export default Contact;