import React from 'react';
import LetsMake from '../../share/LetsMake/LetsMake';
import HelpComponents from '../HelpComponents/HelpComponents';
import HomeHeader from '../HomeHeader/HomeHeader';
import HomeProjects from '../HomeProjects/HomeProjects';
import LetsBring from '../LetsBring/LetsBring';
import Review from '../Review/Review';
import Services from '../Services/Services';

const Home = () => {
    return (
        <div>
            <HomeHeader/>
            <HelpComponents/>
            <LetsBring/>
            <Services/>
            <HomeProjects/>
            <Review/>
            <LetsMake/>
        </div>
    );
};

export default Home;