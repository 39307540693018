import React from 'react';
import { Modal } from 'react-bootstrap';
import animationImg from '../../../image/animation.gif';
import closeImg from '../../../image/close.svg';

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="text-center mb-5">
                <div className="text-end">
                    <img
                        style={{ cursor: 'pointer', margin: '-3% -3% 0 0', width: '13%', boxShadow: '0px 8.12751px 48.7651px rgba(29, 29, 29, 0.3)', borderRadius: '50%'}}
                        onClick={props.onHide}
                        src={closeImg} alt="" />

                </div>
                <br />
                <img className="w-75" src={animationImg} alt="" />
                <h1 className="text-center fw-bold">Email sent!</h1>
                <p>Your mail has been sent successfully.</p>
            </div>
        </Modal>
    );
}

export default MyVerticallyCenteredModal;