import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ProjectContext } from '../../../App';

const Portfolio = () => {
    const [projects] = useContext(ProjectContext);
    const filterProjects = projects.filter(project => project.id !== 16158433);
    return (
        <div className='container'>
            <div className='row mt-5'>
                {
                    filterProjects.map((project, index) => (
                        <Link to={`/workDetails/${project.id}`}className='col-lg-4 col-6 mt-3' data-aos="flip-left" data-aos-duration="2000" key={index}>
                            <div className="image-div">
                                <img className='w-100' src={project?.images?.normal} alt="" />
                            </div>
                        </Link>
                    ))
                }
            </div>
        </div>
    );
};

export default Portfolio;