import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectContext } from '../../../App';
import { useNavigate } from "react-router-dom";
import crossImg from '../../../image/cross.svg';
import LetsMake from '../LetsMake/LetsMake';


const WorkDetails = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const projectIdNumber = parseInt(projectId)
    const [projects] = useContext(ProjectContext);
    const getProject = projects?.find(project => project.id === projectIdNumber);

    return (
        <>
            <div className="container mt-5 mb-5 p-4">
                <h2 className='fw-bolder text-center mt-3 mb-4'>{getProject?.title}</h2>
                <div style={{ position: 'relative' }}>
                    <img data-aos="flip-left" data-aos-duration="1000" style={{ borderRadius: 10, width: '100%' }} src={getProject?.images?.hidpi} alt="" />
                    <div style={{ position: 'absolute', top: '-10px', right: '-18px' }}>
                        <img onClick={() => navigate(-1)} style={{ width: 80, cursor: 'pointer' }} src={crossImg} alt="" />
                    </div>
                </div>
            </div>
            <LetsMake />
        </>
    );
};

export default WorkDetails;