import React from 'react';
import { Link } from 'react-router-dom';

const MobileAppsProject = ({ projects }) => {
    console.log(projects)
    return (
        <div className="row">
            {
                projects.map(project => <Link to={`/workDetails/${project.id}`}
                    key={project.id}
                    className="col-lg-4 col-6 mt-4">
                    <div data-aos="flip-left" data-aos-duration="2000" className='image-div'>
                        <img className='w-100' src={project?.images?.normal} alt="" />
                    </div>
                </Link>)
            }
        </div>
    );
};


export default MobileAppsProject;