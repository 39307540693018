import React from 'react';
import quotesImg from '../../../image/quotes_3.png';
import arrowImg from '../../../image/Arrow.png';
import { Link } from 'react-router-dom';

const LetsMake = () => {
    return (
        <div className='mt-5 mb-5' style={{ background: '#F9572E', textAlign: 'center', padding: '150px 0', color: 'white' }}>
            <h1 className='lg-60-md-30' style={{ fontWeight: 600}} data-aos="fade-up" data-aos-duration="1500">Let's Make something amazing together.</h1>
            <Link to='/contact'>
                <button style={{marginTop: 50}} data-aos="fade-up" data-aos-duration="3000" className='button-white rounded-pill'>
                    <img style={{ width: 40, margin: '0 10px' }} src={quotesImg} alt="" /> Say HI!! <img style={{ height: '100%', padding: '0 0 0 20px' }} src={arrowImg} alt="" />
                </button>
            </Link>

        </div>
    );
};

export default LetsMake;