import React, { useState } from 'react';
import reviewImg from '../../../image/review.png';
import revire from '../../../image/Revire.png';
import arrowPng1 from '../../../image/arrow_1.png';
import arrowPng2 from '../../../image/arrow-2.png';


const reviewsData = [
    {
        id: 1,
        name: 'sukaiii',
        img: reviewImg,
        description: "Axay is truly a stellar UI designer that turned my Mobile App project from wireframe into a reality. Working and communicating with Axay have been always prompt and accurate without issue. Revisions come daily with good quality. I will definitely reach out to Axay again for any future UI design works. Top notch and highly recommend!",
    },
    {
        id: 2,
        name: 'ulrichhutter',
        img: reviewImg,
        description: "very satisfied! fast response, know, what we need, very good design. i will make more projects in future with axay!",
    },
    {
        id: 3,
        name: 'bkelly',
        img: reviewImg,
        description: "Great! Was really great. Just awesome. Really really great. Highly recommend. Very wow. Much great.",
    },

]

const Review = () => {
    const [page, setPage] = useState(1);
    const review = reviewsData.find(rv => rv.id === page);

    const increaseBtn = () => {
        if(page < 3) setPage(page + 1);
        else{
            setPage(1)
        }
    }
    const decreaseBtn = () => {
        if(page > 1) setPage(page - 1);
        else{
            setPage(3)
        }
    }
    return (
        <div className='container'>
            <h1 className='lg-50-md-30' style={{ margin: '80px 0 40px', fontWeight: 600, textAlign: 'center' }}>Client's Kind Word</h1>
            <div className='row align-items-center'>
                <div style={{ position: 'relative' }} className="col-md-5">
                    <img data-aos="flip-left" data-aos-duration="3000" className='w-100' src={review?.img} alt="" />
                    <div style={{ width: '20%', position: 'absolute', top: '1%', right: '15%' }}>
                        <img src={revire} alt="" />
                    </div>
                </div>
                <div className="col-md-7">
                    <p style={{ fontSize: 22, lineHeight: '177%', fontWeight: 400, color: 'rgba(9, 33, 45, 0.69)' }} data-aos="fade-up" data-aos-duration="2000">{review?.description}</p>
                    <div data-aos="fade-up" data-aos-duration="3000" className='d-flex justify-content-between align-items-center mt-5'>
                        <h4 className='fw-bold'>{review?.name}</h4>
                        <div>
                            <img style={{cursor: 'pointer'}} onClick={decreaseBtn} className='m-2' src={arrowPng2} alt="" />
                            <img style={{cursor: 'pointer'}} onClick={increaseBtn} className='m-2' src={arrowPng1} alt="" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Review;