import React, { useContext, useEffect } from 'react';
import whoSvg from '../../../image/who-are-you.svg';
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { UserContext } from '../../../App';
import { initializeApp } from '@firebase/app';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};
initializeApp(firebaseConfig);
const Login = () => {
    const [user, setUser] = useContext(UserContext);
    // console.log(user);

    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    const googleSignInBtn = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                const user = result.user;
                console.log(user);
                setUser(user);
            }).catch((error) => {
                setUser({});
            });
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                console.log(user);
            } else {
                setUser({})
            }
        });
        return () => unsubscribe;
    }, [auth, setUser]);

    return (
        <div className='container'>
            <div className="row mt-5 mb-*5">
                <div className="col-md-6">
                    <img src={whoSvg} alt="" />
                </div>
                <div className="col-md-6">
                    <h1 style={{ color: '#F9572E', fontWeight: 600 }}>
                        Only This website owner can add Blogs.

                        Only This website owner can login.
                    </h1>
                    <br />
                    <div className="text-center">
                        <button
                            className="button-style rounded-pill m-1"
                            onClick={googleSignInBtn}>
                            Sign in Google
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;