import React from 'react';
import { Link } from 'react-router-dom';
import arrowImg from '../../../image/Arrow.png';

const LetsBring = () => {
    return (
        <div className='mt-5 mb-5' style={{ background: '#F9572E', textAlign: 'center', padding: '150px 0', color: 'white' }}>
            <h1 className='lg-50-md-30' style={{ lineHeight: '75px', fontWeight: 600}} data-aos="fade-up" data-aos-duration="1500" >Let's bring your idea into reality.</h1>
            <Link to="/contact">
                <button style={{marginTop: 40, height: 65, width: 223, fontSize: 20, lineHeight: '30px'}} data-aos="fade-up" data-aos-duration="3000" className='button-white rounded-pill'>Get a Quote <img style={{ height: 48 }} src={arrowImg} alt="" /> </button>
            </Link>
        </div>
    );
};

export default LetsBring;