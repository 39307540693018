import React from 'react';
import { Link } from 'react-router-dom';
import arrowImg from '../../../image/Arrow.png';

const HelpComponents = () => {
    const helpMiniService = {
        borderBottom: '1px solid #9D9191',
        padding: '5px 0'
    }
    const serviceHeaderStyle = { fontWeight: 500, padding: '25px 0 30px 0' };
    const serviceParagraphStyle = { paddingBottom: 40, color: 'rgba(9, 33, 45, 0.87)' };

    return (
        <div className='container'>
            <div className='row align-items-center mt-5 mb-5'>
                <div style={{ paddingRight: 31 }} className="col-md-6">
                    <h2 data-aos="fade-up" data-aos-duration="500" className="lg-50-md-30" style={{ fontWeight: 600, marginBottom: 40 }}>What do I help?</h2>
                    <p data-aos="fade-up" data-aos-duration="1500" className='paragraphStyle'>I am a passionate designer from India with over 6 years of experience in UI/UX design, mobile and website design, logo design, branding and art direction. I help both small businesses and startups develop online presence with projects that range from web to mobile applications.</p>
                    <p data-aos="fade-up" data-aos-duration="2500" className='paragraphStyle'>I am a true believer of UI/UX design and I believe in the power of design to make a product/service more efficient to use for the users and to make it stand out from the crowd.</p>
                    <p data-aos="fade-up" data-aos-duration="3000" className='paragraphStyle'> I am always looking for new opportunities to work in creative ways that add value to the people and brands I work with.</p>
                </div>
                <div style={{ paddingLeft: 31 }} className="col-md-6">
                    <div style={{ borderBottom: '1px solid #9D9191', borderTop: '1px solid #9D9191' }} className='row align-items-center'>
                        <div className="col-md-10 col-9">
                            <h3 className="lg-37-md-24" style={serviceHeaderStyle} data-aos="fade-up" data-aos-duration="1500">Web UI/UX Design</h3>
                            <p className="lg-22-md-14" style={serviceParagraphStyle} data-aos="fade-up" data-aos-duration="3000">20 Projects</p>
                        </div>
                        <div className="col-md-2 col-3">
                            <Link to='/portfolio'>
                                <img style={{ width: 54 }} src={arrowImg} alt="" />
                            </Link>
                        </div>
                    </div>
                    <div style={helpMiniService} className="row align-items-center">
                        <div className="col-md-10 col-9">
                            <h3 className="lg-37-md-24" style={serviceHeaderStyle} data-aos="fade-up" data-aos-duration="1500">Mobile App UI/UX Design</h3>
                            <p className="lg-22-md-14" style={serviceParagraphStyle} data-aos="fade-up" data-aos-duration="3000">20 Projects</p>
                        </div>
                        <div className="col-md-2 col-3">
                            <Link to='/portfolio'>
                                <img style={{ width: 54 }} src={arrowImg} alt="" />
                            </Link>
                        </div>
                    </div>
                    <div style={helpMiniService} className="row align-items-center">
                        <div className="col-md-10 col-9">
                            <h3 className="lg-37-md-24" style={serviceHeaderStyle} data-aos="fade-up" data-aos-duration="1500">Dashboard SaaS Design</h3>
                            <p className="lg-22-md-14" style={serviceParagraphStyle} data-aos="fade-up" data-aos-duration="3000">20 Projects</p>
                        </div>
                        <div className="col-md-2 col-3">
                            <Link to='/portfolio'>
                                <img style={{ width: 54 }} src={arrowImg} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default HelpComponents;