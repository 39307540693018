import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { GlobalStyles } from '../../../globalStyle/globalStyle';
import logoImg from '../../../image/Logo.png';

const NavbarHeader = () => {
    const location = useLocation();
    const path = location.pathname;
    return (
        <Navbar style={{ borderBottom: '5px solid #F8F7F1' }} expand="lg">
            <GlobalStyles />
            <Container>
                <Navbar.Brand href="/">
                    <img src={logoImg} alt="" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto text-end">
                        <Link to="/home" className={`nav-link-btn ${path === '/' ? "active-nav-link-btn" : ""} ${path === '/home' ? "active-nav-link-btn" : ""}`}>Home</Link>
                        <Link to="/portfolio" className={`nav-link-btn ${path === '/portfolio' ? "active-nav-link-btn" : ""}`}>Portfolio</Link>
                        <Link to="/about" className={`nav-link-btn ${path === '/about' ? "active-nav-link-btn" : ""}`}>About Me</Link>
                        <Link to="/testimonial" className={`nav-link-btn ${path === '/testimonial' ? "active-nav-link-btn" : ""}`}>Testimonial</Link>
                        {/* <Link to="/blogs" className={`nav-link-btn ${path === '/blogs' ? "active-nav-link-btn" : ""}`}>Blogs</Link> */}
                        <div className="nav-header-button">
                            <Link to="/contact">
                                <button className='button-style rounded-pill m-1'>Let's work together</button>
                            </Link>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarHeader;