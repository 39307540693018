import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './page/home/Home/Home';
import About from './page/about/About/About';
import Contact from './page/contact/Contact/Contact';
import Portfolio from './page/portfolio/Portfolio/Portfolio';
import NavbarHeader from './page/share/NavbarHeader/NavbarHeader';
import Footer from './page/share/Footer/Footer';
import { createContext, useEffect, useState } from 'react';
import WorkDetails from './page/share/WorkDetails/WorkDetails';
import Testimonial from './page/testimonial/Testimonial/Testimonial';
import Blog from './page/blog/Blog/Blog';
import TextEditor from './page/blog/TextEditor/TextEditor';
import PrivateRoute from './page/login/PrivateRoute/PrivateRoute';
import Login from './page/login/Login/Login';
import UpdateTextEditor from './page/blog/UpdateTextEditor/UpdateTextEditor';

export const ProjectContext = createContext();
export const UserContext = createContext();

console.log()

function App() {
  const [user, setUser] = useState({});
  const [projects, setProjects] = useState([]);
  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    fetch(`https://api.dribbble.com/v2/user/shots?page=1&per_page=100&access_token=${process.env.REACT_APP_ACCESS_TOKEN}`)
      .then(res => res.json())
      .then(data => setProjects(data));
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path]);
  return (
    <ProjectContext.Provider value={[projects, setProjects]}>
      <UserContext.Provider value={[user, setUser]}>
        <NavbarHeader />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home/*" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/workDetails/:projectId" element={<WorkDetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/contact" element={<Contact />} />

          {/* <Route path="/blogs" element={<Blog />} />
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/blogs/addBlog" element={<TextEditor />} />
            <Route path="/blogs/updateBlog/:blogId" element={<UpdateTextEditor />} />
          </Route> */}
          
        </Routes>
        <Footer />
      </UserContext.Provider>
    </ProjectContext.Provider>
  );
}

export default App;
