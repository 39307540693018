import React from 'react';
import ReviewImg from '../../../image/review.png';
import revire from '../../../image/Revire.png';
import revireBg from '../../../image/revire-bg.png';
import LetsMake from '../../share/LetsMake/LetsMake';

const reviewsData = [
    {
        id: 1,
        img: ReviewImg,
        name: 'ulrichhutter',
        country: 'Austria',
        description: 'very satisfied! fast response, know, what we need, very good design. i will make more projects in future with axay!',
    },
    {
        id: 2,
        img: ReviewImg,
        name: 'bkelly',
        country: 'USA',
        description: 'Great! Was really great. Just awesome. Really really great. Highly recommend. Very wow. Much great.',
    },
    {
        id: 3,
        img: ReviewImg,
        name: 'bkelly',
        country: 'USA',
        description: 'Great! Was really great. Just awesome. Really really great. Highly recommend. Very wow. Much great.',
    },
    {
        id: 4,
        img: ReviewImg,
        name: 'bkelly',
        country: 'USA',
        description: 'Great! Was really great. Just awesome. Really really great. Highly recommend. Very wow. Much great.',
    },
]

const Testimonial = () => {
    return (
        <>
            <div className="container">
                <div style={{ backgroundImage: `url(${revireBg})`, backgroundPosition: 'top center', backgroundRepeat: 'no-repeat', padding: 50, marginTop: 30 }}>
                    <h1 className="lg-60-md-30" style={{ fontWeight: 600, textAlign: 'center' }}>Some good words from some smart folks</h1>
                </div>
                {
                    reviewsData.map(review => <div
                        key={review.id}
                        // style={{borderBottom: '1px solid #9D9191'}}
                        className='row align-items-center mt-3'>
                        <div style={{ position: 'relative' }} className="col-md-3 col-5 ">
                            <img data-aos="flip-left" data-aos-duration="3000" className='w-100' src={review?.img} alt="" />
                            <div style={{ width: '20%', position: 'absolute', top: '1%', right: '15%' }}>
                                <img style={{ width: 44 }} src={revire} alt="" />
                            </div>
                        </div>
                        <div className="col-md-9 col-7">
                            <p className="lg-22-md-16" style={{ lineHeight: '177%', fontWeight: 400, color: 'rgba(9, 33, 45, 0.69)' }} data-aos="fade-up" data-aos-duration="2000">{review?.description}</p>
                            <div data-aos="fade-up" data-aos-duration="3000" className='mt-4'>
                                <h4 className='fw-bold'>{review?.name}</h4>
                                <p className="lg-22-md-16" style={{ color: 'rgba(9, 33, 45, 0.69)' }}>{review?.country}</p>
                            </div>
                        </div>
                    </div>)
                }
            </div>
            <LetsMake />
        </>
    );
};

export default Testimonial;