import React, { useContext, useEffect } from 'react';
import { getAuth,onAuthStateChanged } from "firebase/auth";
import { UserContext } from '../App';
import { initializeApp } from '@firebase/app';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};
initializeApp(firebaseConfig);
const FirebaseStateChanged = () => {
    const [user, setUser] = useContext(UserContext);
    // console.log(user);
    const auth = getAuth();
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser({})
            }
        });
        return () => unsubscribe;
    }, [auth, setUser]);

    return (
        ''
    );
};

export default FirebaseStateChanged;