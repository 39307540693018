import React from 'react';
import mainImage from '../../../image/Image.png';
import Typewriter from 'typewriter-effect';

const HomeHeader = () => {
    return (
        <div style={{ background: '#F8F7F1' }}>
            <div className="container">
                <div className='row flex-md-row-reverse align-items-center mb-5'>
                    <div data-aos="fade-left" data-aos-duration="3000" className='col-md-6'>
                        <img className='w-100' src={mainImage} alt="" />
                    </div>
                    <div className='col-md-6 '>
                        <h1 className='lg-54-md-33' data-aos="fade-up" data-aos-duration="1000">Hey there,</h1>
                        <h1 className="lg-70-md-43" data-aos="fade-up" data-aos-duration="2000" >I'm Axay Devikar</h1>
                        <h1 className="lg-37-md-24 d-flex" data-aos="fade-up" data-aos-duration="3000">
                            <span>I am a </span> <span className='ps-2' style={{ color: '#F9572E', textDecoration: 'underline' }}><Typewriter
                                options={{
                                    strings: ['UI/UX Designer'],
                                    autoStart: true,
                                    loop: true,
                                }}
                            /></span>
                        </h1>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default HomeHeader;