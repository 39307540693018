import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import './HomeProject.css';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
// import WebDesignProject from './WebDesignProject';
import MobileAppsProject from './MobileAppsProject';
// import WebAppsProjects from './WebAppsProjects';


const HomeProjects = () => {
    const [projects, setProjects] = useState([]);
    const [page, setPage] = useState(2);
    useEffect(() => {
        fetch(`https://api.dribbble.com/v2/user/shots?page=${page}&per_page=6&access_token=${process.env.REACT_APP_ACCESS_TOKEN}`)
            .then(res => res.json())
            .then(data => setProjects(data))
    }, [page]);
    // const location = useLocation();
    // const path = location.pathname;
    return (
        <div style={{ background: '#F8F7F1' }} className='pt-5 pb-5'>
            <div className="container">
            <h1 className="lg-50-md-30" style={{ fontWeight: 600 }}>My Latest Work</h1>
            <MobileAppsProject projects={projects} />

                {/* <div className='row justify-content-between align-items-center pt-4 mb-3'>
                    <div className="col-md-6">
                        <h1 className="lg-50-md-30" style={{ fontWeight: 600 }}>My Latest Work</h1>
                    </div>
                    <div className="d-flex flex-row col-md-6">
                        <Link onClick={() => setPage(1)} to="/home/webDesign"  className={`nav-link-btn ${path === '/home/webDesign' ? "active-nav-link-btn" : ""}`} >Web Design</Link>
                        <Link onClick={() => setPage(2)} to="/" className={`nav-link-btn ${path === '/' ? "active-nav-link-btn" : ""} ${path === '/home' ? "active-nav-link-btn" : ""}`}>Mobile Apps</Link>
                        <Link onClick={() => setPage(3)} to="/home/webApps" className={`nav-link-btn ${path === '/home/webApps' ? "active-nav-link-btn" : ""}`}>Web Apps</Link>
                    </div>
                </div> */}
                {/* <Routes>
                    <Route path="webDesign" element={<WebDesignProject projects={projects} />} />
                    <Route path="/" element={<MobileAppsProject projects={projects} />} />
                    <Route path="webApps" element={<WebAppsProjects projects={projects} />} />
                </Routes> */}
                <div className="text-center mt-5 mb-5">
                    <Link to="/portfolio">
                        <button style={{ width: 186 }} className="rounded-pill button-style">View more <FontAwesomeIcon icon={faArrowRightLong} /></button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default HomeProjects;